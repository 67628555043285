var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { flow, transformOptions, set } from '../../utils';
import { mark } from '../../adaptor';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * 图表差异化处理
     */
    var customTransform = function (params) {
        var options = params.options;
        var xField = options.xField, yField = options.yField, seriesField = options.seriesField, children = options.children;
        var newChildren = children === null || children === void 0 ? void 0 : children.map(function (item) {
            return __assign(__assign({}, item), { xField: xField, yField: yField, seriesField: seriesField, colorField: seriesField, data: item.type === 'density'
                    ? {
                        transform: [
                            {
                                type: 'kde',
                                field: yField,
                                groupBy: [xField, seriesField],
                            },
                        ],
                    }
                    : item.data });
        }).filter(function (item) { return options.violinType !== 'density' || item.type === 'density'; });
        set(options, 'children', newChildren);
        // 默认‘normal’类型数据格式
        if (options.violinType === 'polar') {
            set(options, 'coordinate', { type: 'polar' });
        }
        // 底层不消费violinType字段。
        set(options, 'violinType', undefined);
        return params;
    };
    return flow(customTransform, mark, transformOptions)(params);
}
