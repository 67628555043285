import { flow, transformOptions, set } from '../../utils';
import { mark } from '../../adaptor';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * 图表差异化处理
     */
    var init = function (params) {
        var options = params.options;
        var data = options.data;
        if (data) {
            set(options, 'data', {
                value: data,
            });
        }
        return params;
    };
    return flow(init, mark, transformOptions)(params);
}
