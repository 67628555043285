var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { allCoordinateLayout } from '../../adaptor';
import { flow, transformOptions, map, set, get, isArray, includes, isNumber, mergeWithArrayCoverage, isNil, isString, } from '../../utils';
// 默认颜色
export var DEFAULT_COLORS = ['#f0efff', '#5B8FF9', '#3D76DD'];
/**
 * 转化为扁平化数据
 * 1、[{ measures: [1,2], title: 'x' }, ...] -> [{ measures: 1, title: 'x', index: 0 }, { measures: 2, title: 'x', index: 1 },...]
 * 2、[{ measures: 1, title: 'x' }, { measures: [2,3], title: 'x' }] ->
 * [{ measures: 1, title: 'x', index: 0 }, { measures: 2, title: 'x', index: 0 }, { measures: 3, title: 'x', index: 1 },...]
 * @param data 数据
 * @param field 通道
 * @param xField x 分类通道
 * @param isSort 是否排序（降序）
 * @returns [扁平化的数据, 最大数据量]
 */
function getTransformData(data, field, xField, isSort) {
    if (isSort === void 0) { isSort = true; }
    var maxSize = 0;
    var isArrayData = false;
    var transformData = map(data, function (d) {
        var _a, _b;
        var fieldData = get(d, [field]);
        // null undefined 以及 Number(string) 为 NaN 的 都去除数据
        if (isNil(fieldData))
            return [];
        if (isString(fieldData)) {
            var numberData = Number(fieldData);
            if (isNaN(numberData))
                return [];
            return _a = {}, _a[xField] = d[xField], _a[field] = numberData, _a;
        }
        // 主要组成为数组的形式
        if (isArray(fieldData)) {
            isArrayData = true;
            maxSize = Math.max(maxSize, fieldData.length);
            return map(isSort ? fieldData.sort(function (a, b) { return b - a; }) : fieldData, function (value, index) {
                var _a;
                return (_a = {},
                    _a[xField] = d[xField],
                    _a[field] = value,
                    _a.index = index,
                    _a);
            });
        }
        // 存在则 min 值为 1
        maxSize = Math.max(1, maxSize);
        return _b = {}, _b[xField] = d[xField], _b[field] = fieldData, _b;
    }).flat();
    // 当存在更多分类时，单一的 measures 从 'measures' 的分类，变更为 'measures_0' 的分类
    if (isArrayData) {
        return [
            transformData.map(function (item) { return (__assign({ index: 0 }, item)); }),
            maxSize,
        ];
    }
    return [transformData, maxSize];
}
/**
 * 按照最大数据量转化为颜色数组
 * @param maxSize 最大数据量
 * @param color 颜色
 * @returns string[]
 */
function getFieldColor(maxSize, color) {
    return new Array(maxSize).fill('').map(function (d, i) { return (isArray(color) ? color[i % color.length] : color); });
}
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * 图表差异化处理
     */
    var init = function (params) {
        var _a = params.options, color = _a.color, _b = _a.rangeField, rangeField = _b === void 0 ? 'ranges' : _b, _c = _a.measureField, measureField = _c === void 0 ? 'measures' : _c, _d = _a.targetField, targetField = _d === void 0 ? 'targets' : _d, _e = _a.xField, xField = _e === void 0 ? 'title' : _e, mapField = _a.mapField, data = _a.data;
        // 数据进行拍平
        var _f = getTransformData(data, rangeField, xField), rangesData = _f[0], rangesMaxSize = _f[1];
        var _g = getTransformData(data, measureField, xField, false), measuresData = _g[0], measuresMaxSize = _g[1];
        var _h = getTransformData(data, targetField, xField, false), targetsData = _h[0], targetsMaxSize = _h[1];
        // 获取颜色
        var rangesColor = get(color, [rangeField], DEFAULT_COLORS[0]);
        var measuresColor = get(color, [measureField], DEFAULT_COLORS[1]);
        var targetsColor = get(color, [targetField], DEFAULT_COLORS[2]);
        // 获取 scale.color 颜色分类
        var colors = [
            getFieldColor(rangesMaxSize, rangesColor),
            getFieldColor(measuresMaxSize, measuresColor),
            getFieldColor(targetsMaxSize, targetsColor),
        ].flat();
        params.options.children = map(params.options.children, function (c, i) {
            var datas = [rangesData, measuresData, targetsData][i];
            var yField = [rangeField, measureField, targetField][i];
            return __assign(__assign({}, c), { data: datas, encode: __assign(__assign({}, (c.encode || {})), { x: xField, y: yField, color: function (d) {
                        var index = d.index;
                        var mapString = isNumber(index) ? "".concat(yField, "_").concat(index) : yField;
                        return mapField ? get(mapField, [yField, index], mapString) : mapString;
                    } }), style: __assign(__assign({}, (c.style || {})), { 
                    // 层级
                    zIndex: function (d) { return -d[yField]; } }), 
                // labels 对应的 yField
                labels: i !== 0 ? map(c.labels, function (l) { return (__assign(__assign({}, l), { text: yField })); }) : undefined });
        });
        params.options.scale.color.range = colors;
        // legend itemMarker 的形状
        params.options.legend.color.itemMarker = function (d) {
            if (mapField && includes(mapField === null || mapField === void 0 ? void 0 : mapField[targetField], d)) {
                return 'line';
            }
            return (d === null || d === void 0 ? void 0 : d.replace(/\_\d$/, '')) === targetField ? 'line' : 'square';
        };
        return params;
    };
    /**
     * 水平｜竖直 方向target配置适配
     * @param params Params
     * @returns params Params
     */
    var layoutAdaptor = function (params) {
        var _a = params.options.layout, layout = _a === void 0 ? 'horizontal' : _a;
        if (layout !== 'horizontal') {
            set(params, 'options.children[2].shapeField', 'hyphen');
        }
        return params;
    };
    /**
     * range、measure、target 配置适配
     * @param params Params
     * @returns params Params
     */
    var cfgAdaptor = function (params) {
        var _a = params.options, _b = _a.range, range = _b === void 0 ? {} : _b, _c = _a.measure, measure = _c === void 0 ? {} : _c, _d = _a.target, target = _d === void 0 ? {} : _d, children = _a.children;
        params.options.children = [range, measure, target].map(function (c, i) { return mergeWithArrayCoverage(children[i], c); });
        return params;
    };
    return flow(init, layoutAdaptor, cfgAdaptor, allCoordinateLayout, transformOptions)(params);
}
